import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Grid, Row, Col, Button, Dropdown, MenuItem} from 'react-bootstrap';
import {css} from 'aphrodite';
import {nodeId} from 'react-widgets/libs';
import ReactMarkdown from 'react-markdown';
import DropdownSelector from 'components/DropdownSelector';

const Header = (props) => {
  const {
    settings,
    styleSheet,
    authenticated,
    logoAssets,
    handleLogout,
  } = props;

  const productLogo = <img src={logoAssets[settings.header.productLogo]} className={css(styleSheet.headerLogo)} alt={settings.content.productLogoAlt} />;

  const headerLinks = settings.header.links.map((link) => {
    // Relative links should be routed within reactrouter
    if (link.href.charAt(0) === '/') {
      return <Link key={nodeId()} to={link.href} className={css(styleSheet.headerLink, styleSheet.headerPageLink)} target={link.newTab ? '_blank' : null}>{link.name}</Link>;
    }
    return <a key={nodeId()} className={css(styleSheet.headerLink)} href={link.href} target={link.newTab ? '_blank' : null}>{link.name}</a>;
  });

  const linkSectionGridWidth = 12 - settings.header.logoSectionGridWidth;

  return (
    <div>
      {!settings.header.headerBar.disabled && settings.header.headerBar.markdown &&
        <div className={css(styleSheet.headerBar)}>
          <ReactMarkdown escapeHtml={false} source={settings.header.headerBar.markdown} />
        </div>
      }
      <header data-component="Header" className={css(styleSheet.header)}>
        <Grid>
          <Row>
            <Col sm={settings.header.logoSectionGridWidth}>
              {settings.header.productLogo &&
                (settings.header.productLogoLink.charAt(0) === '/' ?
                  <Link to={settings.header.productLogoLink}>{productLogo}</Link>
                  :
                  <a href={settings.header.productLogoLink}>{productLogo}</a>)
              }
            </Col>
            <Col sm={linkSectionGridWidth}>
              {authenticated
                && <a className={css(styleSheet.headerLink)} onClick={handleLogout}>Logout</a>}
              {headerLinks}
              {settings.header.dropdown && settings.header.dropdown.enabled &&
                <div className={'pull-right'}>
                  <label htmlFor="linkOutSelector" className={css(styleSheet.linkOutLabel)}>Go to Product:</label>
                  <DropdownSelector id="linkOutSelector"
                    menuItems={settings.header.dropdown.menuItems}
                    placeholderText={settings.header.dropdown.placeholderText}
                    buttonLabel={settings.header.dropdown.buttonLabel}
                    newTab={settings.header.dropdown.newTab}
                    dropdownContainerStyle={'pull-right'}
                    dropdownSelectorStyle={css(styleSheet.dropdownSelectorDropdown)}
                    dropdownToggleStyle={css(styleSheet.dropdownSelectorToggle)}
                    gobuttonStyle={css(styleSheet.dropdownSelectorButton)}
                  />
                </div>}
              <p className={'pull-right'}>{settings.content.headerUsageDisclaimer}</p>
            </Col>
          </Row>
        </Grid>
      </header>
    </div>
  );
};

Header.protoTypes = {
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  logoAssets: PropTypes.object.isRequired,
  authenticated: PropTypes.bool,
  handleLogout: PropTypes.func.isRequired,
};

export default Header;
