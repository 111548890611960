import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {
  filter,
  includes,
  map,
  uniq,
  intersection,
  merge,
  flatten,
  keyBy,
  mapValues,
  difference,
  find,
} from 'lodash';
import {
  Button,
  Grid,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import {css} from 'aphrodite';
import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import {faFile, faFileMedicalAlt} from '@fortawesome/fontawesome-pro-regular';
import {findIconDefinition} from '@fortawesome/fontawesome-svg-core';
import {
  faFile as faFileSolid,
  faFileMedicalAlt as faFileMedicalAltSolid,
  faInfoCircle
} from '@fortawesome/fontawesome-pro-solid';

import {
  getStatePayerCoverageList,
  getStateCoverageProfiles,
  setStateCoverageShowAlert,
  ERROR_ALERT,
  NO_ROW_ALERT,
} from 'actions/coverage';

import {
  getError,
  getInProgress,
  getLastRefreshedDate,
  getShowErrorAlert,
  getShowNoRowsAlert,
} from 'selectors/coverage';

import {
  addContactIcons,
  filterForMapStates,
  orderData,
  renderMultifieldString,
  validateObjectProperties,
} from 'libs/salmon-utils';

import {getSettings} from 'selectors/settings';

import {getErrorField, hasClass, nodeId} from 'react-widgets/libs';
import {DataTable, ErrorAlert, Loader, MultiSelect, ToolTipCell, USMap} from 'react-widgets/components';
import {USStates} from 'react-widgets/components/USMap';
import Isi from 'components/Isi';
import StateCoverageProfileDocumentDownload from 'components/StateCoverageProfileDocumentDownload';
import {
  ExitModal,
  FeatureModal,
  CoverageSummaryModal,
  DisclaimerModal,
  ContactInstructionsModal,
} from '../Modals';
import './styles.scss';

const queryString = require('query-string');

export class Coverage extends Component {
  constructor(props) {
    super(props);

    // Determine whether the PA tooltip should be displayed or not
    function ShouldHidePaTooltip() {
      const documentUrlColumn = props.settings.table.columns.find((column) => column.property === 'documentUrl');
      return documentUrlColumn ? documentUrlColumn.hidePAFormIfNoPAData : false;
    }

    const absoluteLink = /^https?:\/\//i;
    //
    const summaryColumns = [
      {
        property: 'id',
        sortable: false,
        header: {
          label: '',
        },
        cell: {
          formatters: [
            (value) => {
              return (<strong>{value}</strong>);
            },
          ],
        },
      },
      {
        property: 'initialTherapy',
        sortable: false,
        header: {
          label: 'Initial Therapy',
        },
        cell: {
          formatters: [
            (value) => {
              return (<span className={css(props.styleSheet.initialTherapyCell)}>{value}</span>);
            },
          ],
        },
        props: {
          className: css(props.styleSheet.initialTherapyColumn),
        },
      },
      {
        property: 'continuedTherapy',
        sortable: false,
        header: {
          label: 'Continued Therapy',
        },
        cell: {
          formatters: [
            (value) => {
              return (<span className={css(props.styleSheet.continuedTherapyCell)}>{value}</span>);
            },
          ],
        },
        props: {
          className: css(props.styleSheet.continuedTherapyColumn),
        },
      },
    ];

    const coverageColumns = props.settings.table.columns.map((column) => {
      let dataTableColumn = {
        property: column.property,
        sortable: column.sortable,
        header: {
          label: column.label,
        },
        cell: {
          transforms: [
            (value, {rowData}) => {

              // If rowData is verballyConfirmed, highlight it.
              if (rowData.verballyConfirmed) {
                return {className: css(props.styleSheet.highlightRow)};
              }

              // If the rowData meets the highlightRowCriteria, highlight it.
              let highlightClass = {className: null};
              if (props.settings.table.highlightRowCriteria) {
                for (const rowCriteria of props.settings.table.highlightRowCriteria) {
                  if (validateObjectProperties(rowData, rowCriteria)) {
                    highlightClass.className = css(props.styleSheet.highlightRow);
                    break;
                  }
                }
                return highlightClass;
              }
            },
          ],
        },
      };

      let styleName = `tableColumn${column.property}`;

      if (props.styleSheet[styleName]) {
        dataTableColumn.props = {
          className: css(props.styleSheet[styleName]),
        };
      }

      // Special column-specific formatting defined here
      if (column.property === 'payer') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              let showHighlightRowDisclaimer = false;
              if (props.settings.table.highlightRowCriteria) {
                for (const rowCriteria of props.settings.table.highlightRowCriteria) {
                  if (validateObjectProperties(rowData, rowCriteria)) {
                    showHighlightRowDisclaimer = true;
                    break;
                  }
                }
              }

              if (rowData.verballyConfirmed || showHighlightRowDisclaimer) {
                return (
                  <div>
                    <p>{value}</p>
                    <div className={css(props.styleSheet.highlightRowPara)}>
                      <a className={css(props.styleSheet.highlightRowDisclaimer)} onClick={() => this.setState({showDisclaimerModal: true})}>
                        <ReactMarkdown escapeHtml={false} source={props.settings.table.highlightRow.disclaimerText} />
                      </a>
                    </div>
                  </div>
                );
              } else {
                return value;
              }
            },
          ],
        };
      } else if (column.property === 'planType') {
        if (props.settings.content.planTypeHelpHover.length) {
          dataTableColumn.header = {
            label: (<span>
              {column.label}
              <ToolTipCell
                key={nodeId()}
                text={props.settings.content.planTypeHelpHover}
                placeholder={(<FontAwesomeIcon icon={faInfoCircle}/>)}
                placement={'bottom'}
                styleSheet={props.styleSheet}
                id={'plantype-tooltip'}
              />
            </span>),
          };
        }
      } else if (column.property === 'states') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              if (value.length > 5) {
                return (
                  <ToolTipCell
                    text={value.join(', ')}
                    placement={'bottom'}
                    styleSheet={props.styleSheet}
                    placeholder={`${value.slice(0, 5).join(', ')} ...`}
                    id={`${rowData.id}-states`}
                  />
                );
              } else {
                return (<span>{value.join(', ')}</span>);
              }
            },
          ],
        };
      } else if (column.property === 'documentUrl') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              return (
                <div>
                  {value.priorAuthorizationUrl && absoluteLink.test(value.priorAuthorizationUrl)
                    ?
                    <>
                      <a
                        onClick={this._handleDocumentClick}
                        href={value.priorAuthorizationUrl}
                      >
                        <FontAwesomeIcon
                          icon={faFileMedicalAlt}
                        />
                        PA Form
                      </a>
                      <br/>
                    </>
                    :
                    <>
                      {(
                        ShouldHidePaTooltip()
                      )
                        ?
                        <></>
                        :
                        <>
                          <ToolTipCell
                            placement={'bottom'}
                            styleSheet={props.styleSheet}
                            text={rowData.verballyConfirmed ? props.settings.content.unavailableDocumentHoverPaForVerballyConfirmed : props.settings.content.unavailableDocumentHoverPa}
                            placeholder={(<span><FontAwesomeIcon icon={faFileMedicalAlt} />PA Form</span>)}
                            id={'pa-tooltip'}
                          />
                          <br/>
                        </>
                      }
                    </>
                  }
                  {value.policyUrl && absoluteLink.test(value.policyUrl) ?
                    <a onClick={this._handleDocumentClick} href={value.policyUrl}><FontAwesomeIcon icon={faFile} />Coverage Document</a> :
                    <ToolTipCell
                      placement={'bottom'}
                      styleSheet={props.styleSheet}
                      text={rowData.verballyConfirmed ? props.settings.content.unavailableDocumentHoverCoverageForVerballyConfirmed : props.settings.content.unavailableDocumentHoverCoverage}
                      placeholder={(<span><FontAwesomeIcon icon={faFile} />Coverage Document</span>)}
                      id={'doc-tooltip'}
                    />
                  }
                </div>
              );
            },
          ],
        };
      } else if (column.property === 'salmonDocuments') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              const salmonDocumentLink = (title, resourceurl, handleDocumentClick) => {
                switch (title) {
                  case 'PA Form':
                  case 'PA Criteria':
                    return (
                      <a onClick={handleDocumentClick} href={resourceurl} key={nodeId()}><FontAwesomeIcon icon={faFileMedicalAltSolid} />{title}</a>
                    );
                  case 'Coverage Document':
                  default:
                    return (
                      <a onClick={handleDocumentClick} href={resourceurl} key={nodeId()}><FontAwesomeIcon icon={faFileSolid} />{title}</a>
                    );
                }
              }
              return (
                <div>{rowData.salmonDocuments.map((el) => (
                  <>
                    {salmonDocumentLink(el.title, el.resourceurl, this._handleDocumentClick)}
                    <br/>
                  </>
                ))}</div>
              );
            },
          ],
        };
      } else if (column.property === 'coverageSummary') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              return (<a id={rowData.id} onClick={this._handleSummaryClick}><FontAwesomeIcon icon={faFileSolid} size={'2x'} /></a>);
            },
          ],
        };
      } else if (column.property === 'medicalNecessityChecklist') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              return (<a
                id={rowData.id}
                onClick={() => this._handleOpenMedicalCoverage(props.settings, rowData)}
              ><FontAwesomeIcon icon={faFileSolid} size={'2x'} /></a>);
            },
          ],
        };
      } else if (column.property === 'contacts') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value, {rowData}) => {
              // Get contacts to show in the main table.
              let showContacts = [];
              value.forEach((val) => {
                if (column.displayContacts.includes(val.type)) {
                  showContacts.push(val);
                }
              });

              // Add dummy objects for any missing contacts that need to be displayed in the main table.
              if (column.showMissingContacts) {
                const missingContacts = difference(
                  column.displayContacts,
                  showContacts.map((v) => {
                    return v.type;
                  })
                );

                missingContacts.forEach((x) => {
                  showContacts.push({type: x});
                });
              }

              // Add the icon and tooltip related properties to each contact.
              addContactIcons(orderData(showContacts, 'type', column.sortOrder), column.iconTypes);

              // Show the contact instructions link if we have additional
              // information not already shown on the table.
              let showContactInstructionsLink = (
                (rowData.contactModalData.contactinstructions != null) ||
                (rowData.contactModalData.peertopeer != null) ||
                (rowData.contactModalData.expeditedpa != null)
              );

              // Loop through modalOnlyContacts for contacts not shown on the main table.
              // If there are, set showContactInstructionsLink = true;
              if (!showContactInstructionsLink) {
                const modalOnlyContacts = difference(column.modalContacts, column.displayContacts);
                for (let contactType of modalOnlyContacts) {
                  for (let contact of rowData.contacts) {
                    if (contact.type === contactType) {
                      showContactInstructionsLink = true;
                      break;
                    }
                  }
                }
              }

              let contactInstructions = false;
              if (showContactInstructionsLink && column.contactInstructions) {
                contactInstructions = (<a id={rowData.id} onClick={this._handleContactInstructionsClick}>Contact Instructions</a>);
              }

              const contacts = showContacts.map((item) => {
                const iconLookup = {prefix: item.iconPrefix, iconName: item.iconName};
                let currentContact = (
                  <span key={nodeId()}>
                    <FontAwesomeIcon icon={findIconDefinition(iconLookup)} flip="horizontal"/>
                    {item.contact ? item.contact : item.default}<br/>
                  </span>
                );

                // Add tooltip hover if tooltip aka title exists.
                if (item.title) {
                  currentContact = (
                    <ToolTipCell
                      key={nodeId()}
                      text={item.title}
                      placement={item.tooltipAlign}
                      styleSheet={props.styleSheet}
                      placeholder={currentContact}
                      id={'contact-tooltip'}
                    />
                  );
                }

                return currentContact;
              });

              return (
                <div>
                  {contacts}
                  {contactInstructions}
                </div>
              );
            },
          ],
        };
      } else if (column.property === 'indications') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value) => {
              return (<span>{value.join(', ')}</span>);
            },
          ],
        };
      } else if (column.property === 'covered') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value) => {
              let covered = this._triStateDisplay(value);
              if (column.customCoveredDisplay) {
                covered = column.customCoveredDisplay.mappings[covered];
              }
              return covered;
            },
          ],
        };
      } else if (column.property === 'priorAuthorization') {
        dataTableColumn.cell = {
          ...dataTableColumn.cell,
          formatters: [
            (value) => {
              return this._triStateDisplay(value);
            },
          ],
        };
      }

      return dataTableColumn;
    });

    this.state = {
      selectedPayers: [], // multi-select values
      selectedStates: [],
      selectedIndications: [],
      selectedPlanTypes: [],
      selectedProduct: [],
      previousSelectedProduct: null,
      filteredPayers: [], // locked-in values after hitting search
      filteredStates: [],
      filteredIndications: [],
      filteredPlanTypes: [],
      filteredProduct: [], // single select with first element of the array
      results: false,
      tableColumns: coverageColumns,
      summaryColumns,
      coverageSummary: {},
      showExitModal: false,
      showSummaryModal: false,
      showContactInstructionsModal: false,
      showDisclaimerModal: false,
      showFeatureModal: this.props.coveragePositions.length === 0 ? !props.settings.newFeatureModal.disabled : false,
      showInstruction: true,
      urlParameters: queryString.parse(
        window.location.search,
        {
          arrayFormat: 'bracket',
          parseBooleans: true,
        }
      ),
    };

    this.handleChange = this.handleChange.bind(this);
    this._handleHide = this._handleHide.bind(this);
    this._handleDocumentClick = this._handleDocumentClick.bind(this);
    this._handleSummaryClick = this._handleSummaryClick.bind(this);
    this._handleContactInstructionsClick = this._handleContactInstructionsClick.bind(this);
    this._handleOpenDocument = this._handleOpenDocument.bind(this);
    this._handleOpenMedicalCoverage = this._handleOpenMedicalCoverage.bind(this);
    this._filterCoverage = this._filterCoverage.bind(this);
    this._handleUSMapClick  = this._handleUSMapClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if url parameters exist.
    if (this.props.usStates.length &&
        JSON.stringify(this.props.usStates) !== JSON.stringify(prevProps.usStates) &&
        Object.keys(this.state.urlParameters).length
    ) {
      let newState = {};

      // Apply state filters if exist.
      if (this.state.urlParameters[this.props.settings.urlParametersMap.state]) {
        newState = merge(newState, this._updateStatesFilterFromUrl());
      }

      this.setState(newState);
    }
  }

  componentDidMount() {
    const {
      dispatchGetStatePayerCoverageList,
      dispatchGetStateCoverageProfiles,
      settings,
    } = this.props;

    // don't fetch again if component remounts
    if (this.props.coveragePositions.length === 0) {
      if (settings.domain) {
        dispatchGetStatePayerCoverageList(settings.domain, settings.content.searchErrorUnavailable);
        if (settings.stateCoverageProfiles && settings.stateCoverageProfiles.enabled) {
          dispatchGetStateCoverageProfiles(settings.domain);
        }
      } else if (settings.drugId) {
        dispatchGetStatePayerCoverageList(settings.drugId, settings.content.searchErrorUnavailable);
      }
    }
  }

  handleChange(selected, type) {
    // eslint-disable-next-line
    switch (type) {
      case 'payers': this.setState({selectedPayers: selected});
        break;
      case 'usStates': this.setState({selectedStates: selected});
        break;
      case 'indications': this.setState({selectedIndications: selected});
        break;
      case 'planTypes': this.setState({selectedPlanTypes: selected});
        break;
      case 'products':
        //disallow multiple select
        this.setState({selectedProduct: selected.length > 1 ? selected.splice(1, 1) : selected});
        break;
      default:
        break;
    }
  }

  _handleMapStateClick(el) {
    const {
      selectedStates,
    } = this.state;

    const currentElement = el.currentTarget;
    const clickedState = find(this.props.usStates, {id: currentElement.id});

    let currentSelectedStates = [...selectedStates];
    if (hasClass(currentElement, 'active')) {
      currentSelectedStates = currentSelectedStates.filter(
        (state) => state.id !== currentElement.id
      );
    } else {
      currentSelectedStates = [...currentSelectedStates, clickedState];
    }

    this.setState({
      selectedStates: currentSelectedStates,
    });
  }

  _filterCoverage() {
    const {
      settings,
      planTypes,
    } = this.props;

    const {
      selectedStates,
      selectedPayers,
      selectedIndications,
      selectedPlanTypes,
      selectedProduct,
    } = this.state;

    // Extract visible columns from settings
    const visibleColumns = settings.table.columns.map((column) => column.property);

    // Filter planTypes based on selectedPlanTypes and visible columns
    const filteredPlanTypes = selectedPlanTypes.reduce((acc, selectedPlanType) => {
      if (typeof(selectedPlanType.id) === 'number') {
        return acc.concat(planTypes.filter((planType) =>
          planType.id === selectedPlanType.id && visibleColumns.includes('planType')
        ));
      } else {
        let mappedPlanTypes = selectedPlanType.id.split(',');
        return acc.concat(planTypes.filter((planType) =>
          mappedPlanTypes.includes(planType.id.toString()) && visibleColumns.includes('planType')
        ));
      }
    }, []);

    // Update browser url parameters.
    const newUrlParameters = this._updateUrlStateParameters(selectedStates);
    const newUrl = queryString.stringify(newUrlParameters, {arrayFormat: 'bracket'});
    window.history.pushState(newUrl, '', `/?${newUrl}`);

    this.setState({
      results: true,
      showInstruction: settings.content.alwaysShowInstruction,
      filteredPayers: visibleColumns.includes('payer') ? selectedPayers : [],
      filteredStates: visibleColumns.includes('states') ? selectedStates : [],
      filteredIndications: visibleColumns.includes('indications') ? selectedIndications : [],
      urlParameters: newUrlParameters,
      filteredPlanTypes,
      filteredProduct: visibleColumns.includes('products') ? selectedProduct : [],
    }, () => {
      this._setAlert(NO_ROW_ALERT, true);
      if (this.props.settings.jumpToResultsAfterSearching) {
        document.getElementById('results').scrollIntoView();
      }
    });
  }

  /**
   * Returns an object containing the States(/US Territory) used to update
   * `this.state.urlParameters`.
   *
   * @param {array} selectedStates contains the id/abbrev. of a State.
   * @example [{id: 'AL'}, {id: 'NJ'}, ...]
   *
   * @returns {object}
   */
  _updateUrlStateParameters(selectedStates) {
    let newUrlParameters = this.state.urlParameters;
    newUrlParameters[this.props.settings.urlParametersMap.state] = uniq(
      selectedStates.map((x) => {
        return x.id;
      })
    );

    return newUrlParameters;
  }

  /**
   * Get the 2 letter State abbrev. from the URL parameters
   * and map them to the this.props.usStates objects.
   * Returns an object used to update `this.state`.
   *
   * @returns {object} contains the updated state data
   */
  _updateStatesFilterFromUrl() {
    const stateParameters = this.state.urlParameters[this.props.settings.urlParametersMap.state];

    let validStates = uniq(stateParameters.map((x) => {
      return this.props.usStates.find( (y) => {
        return y.id === x;
      });
    }));

    validStates = validStates.filter(Boolean);

    return {
      results: Boolean(validStates.length), // Show the results if any exist.
      filteredStates: validStates,
      selectedStates: validStates,
    };
  }

  _triStateDisplay(field, defaultValue = 'Unspecified') {
    return field === true
      ? 'Yes'
      : ((field === false) ? 'No' : defaultValue);
  }

  _setAlert(type, value) {
    this.props.dispatchSetStateCoverageShowAlert({
      type,
      value,
    });
  }

  // Adds text property to the fields and populate it from rowData.
  _addTextToFields(fields, rowData) {
    return fields.map((el) => {
      let data = rowData[el.property];
      if (Array.isArray(data)) {
        el.text = data.length > 0 ? data.sort().join(', ') : el.default;
      } else {
        el.text = data ? data : el.default;
      }
      return el;
    });
  }

  _sortProducts(products, sortOrder) {
    return products.sort((a, b) => sortOrder.indexOf(a.id) - sortOrder.indexOf(b.id));
  }

  _handleUSMapClick(el) {
    const {
      selectedStates,
    } = this.state;

    const currentElement = el.currentTarget;
    let include = !hasClass(currentElement, 'active');

    let currentSelectedStates = filterForMapStates(selectedStates, currentElement.id, include, USStates);

    this.setState({
      selectedStates: currentSelectedStates,
    });
  }

  _handleHide() {
    this.setState({
      showExitModal: false,
      showSummaryModal: false,
      showFeatureModal: false,
      showDisclaimerModal: false,
      showContactInstructionsModal: false,
    });
  }

  _handleDocumentClick(e) {
    e.preventDefault();

    this.setState({
      showExitModal: true,
      documentLink: e.currentTarget.href,
    });
  }

  _handleSummaryClick(e) {
    e.preventDefault();

    this.setState({
      showSummaryModal: true,
      coverageSummaryClickedId: e.currentTarget.id,
    });
  }

  _handleContactInstructionsClick(e) {
    e.preventDefault();
    this.setState({
      showContactInstructionsModal: true,
      contactInstructionsClickedId: e.currentTarget.id,
    });
  }

  _handleOpenDocument() {
    window.open(this.state.documentLink, '_blank');

    this.setState({
      showExitModal: false,
    });
  }

  _handleOpenMedicalCoverage(settings, rowData, storageID) {
    const lastRefreshedDate = this.props.lastRefreshedDate;
    let disclaimer = settings.content.medicalNecessity.disclaimer;
    if (lastRefreshedDate) {
      disclaimer = disclaimer.replace(/\[\[(.*)\]\]/, lastRefreshedDate);
    }

    const coverageData = {
      fillInTheBlanks: settings.content.medicalNecessity.fillInTheBlanks,
      title: renderMultifieldString(settings.content.medicalNecessity.title, rowData, settings.content.medicalNecessity.allStates),
      fields: this._addTextToFields(settings.content.medicalNecessity.fields, rowData),
      disclaimer: disclaimer,
      medicalNecessityStyles: settings.styles.medicalNecessityChecklist,
      header: {
        logo: settings.header.productLogo,
        style: settings.styles.headerLogo,
      },
    };

    const sID = storageID ? storageID : Math.floor(Math.random() * 1000000);
    localStorage.setItem(`medicalNecessityChecklist-${sID}`, JSON.stringify(coverageData));
    window.open(`medicalNecessityChecklist?id=${sID}`, '_blank');

    return sID;
  }

  render() {
    const {
      selectedStates,
      selectedPayers,
      selectedIndications,
      selectedPlanTypes,
      selectedProduct,
      filteredStates,
      filteredPayers,
      filteredIndications,
      filteredPlanTypes,
      filteredProduct,
      results,
      tableColumns,
      summaryColumns,
      coverageSummaryClickedId,
      contactInstructionsClickedId,
      showExitModal,
      showSummaryModal,
      showContactInstructionsModal,
      showFeatureModal,
      showDisclaimerModal,
      showInstruction,
    } = this.state;

    const {
      inProgress,
      usStates,
      planTypes,
      payers,
      indications,
      products,
      coveragePositions,
      stateCoverageProfiles,
      settings,
      styleSheet,
      error,
      lastRefreshedDate,
      showErrorAlert,
      showNoRowsAlert,
    } = this.props;

    let coverageSummaryHashMap = {};
    let contactInstructionsHashMap = {};
    let tableRows = [];

    if (results && this.props.usStates.length) {
      const indicationsHashMap = indications.reduce((acc, indication) => {
        let id = indication.id;
        acc[id] = indication.label;
        return acc;
      }, {});

      // Reducer callback for recursively reducing filteredPlanTypes into set of all matching plantypes,
      // including all descendants
      const recurseChildrenPlanType = (acc, planType) => {
        let childrenPlanTypes = map(filter(planTypes, ['parentname', planType]), 'label');
        return acc.concat(childrenPlanTypes.reduce(recurseChildrenPlanType, childrenPlanTypes));
      };
      const matchingPlanTypes = map(filteredPlanTypes, 'label').reduce(recurseChildrenPlanType, map(filteredPlanTypes, 'label'));

      //TODO: Refactor filter of coverage position data by not using filteredXXXX (like filteredPayers)
      let filteredPositions = coveragePositions.reduce((acc, val) => {
        if ((filteredPayers.length === 0) || includes(map(filteredPayers, 'id'), val.payerid)) {
          if ((filteredIndications.length === 0) || (intersection(map(filteredIndications, 'id'), val.indicationIds).length !== 0)) {
            let filteredNodes = filter(val.presenceNodes, (node) => {
              // Filter presenceNodes by states. No filter corresponding to the filter array being empty
              let stateExist = (filteredStates.length === 0) ? true : includes(map(filteredStates, 'id'), node.stateAbbr);
              // Filter presenceNodes by plantypes, including node if plantype (not originalPlanType)
              // matches one of the pre-generated matching plantypes array
              let planExist = (filteredPlanTypes.length === 0) ? true : includes(matchingPlanTypes, node.planType);

              return planExist && stateExist;
            });

            // Filter for indications and use indication label in column data
            if (filteredIndications.length !== 0) {
              val.indications = filteredIndications.reduce((acc, indicationId) => {
                if (includes(val.indicationIds, indicationId.id)) {
                  acc.push(indicationId.label);
                }

                return acc;
              }, []);
            } else {
              val.indications = val.indicationIds.map((id) => indicationsHashMap[id]);
            }

            acc.push({
              ...val,
              presenceNodes: filteredNodes,
            });
          }
        }

        return acc;
      }, []);

      if (filteredProduct.length !== 0) {
        filteredPositions = filteredPositions.filter( (position) => {
          return position.product.id === filteredProduct[0].id;
        });
      }


      /* Check if we do rollup per #2281 or display plans that match the filteredPlanTypes per #7620 */
      // Default behaviour
      let dynamicRollupEnabled = false;
      if (settings.filterLayout && settings.filterLayout.length) {
        const filterLayout = flatten(settings.filterLayout);
        const planFilterSetting = filterLayout.find((filterSetting) => {
          return (filterSetting.filter === 'plan');
        });
        dynamicRollupEnabled = (planFilterSetting && planFilterSetting.dynamicRollup);
      }
      // get parent nodes from planTypes tree
      const parentPlanTypeNames = map(
        planTypes.filter((planType) => planType.parentname === null),
        (parentPlanType) => parentPlanType.label
      );
      // Check if selected filters are parent plan types
      // If even 1 filtered plan type is not a parent, return true
      const hasChildFilter = filteredPlanTypes.some((filteredPlanType) => {
        return (parentPlanTypeNames.indexOf(filteredPlanType.label) === -1);
      });

      filteredPositions.forEach((position) => {
        // Dynamically check if we do rollup per #2281 or display plans that match the filteredPlanTypes per #7620
        const planTypeMustMatchFilteredPlanTypes = dynamicRollupEnabled && hasChildFilter;
        let planTypes = [];

        // Get planTypes that match the actual plan type values the user filtered for.
        if (planTypeMustMatchFilteredPlanTypes) {
          let validPlanTypes = new Set(map(filteredPlanTypes, 'label'));

          let planTypeMatches = [];
          position.presenceNodes.forEach((val) => {
            if (validPlanTypes.has(val.planType)) {
              planTypeMatches.push(val.planType);
            }
            if (validPlanTypes.has(val.originalPlanType)) {
              planTypeMatches.push(val.originalPlanType);
            }
          });

          planTypes = uniq(planTypeMatches);
        } else {
          planTypes = uniq(map(position.presenceNodes, 'originalPlanType'));
        }

        planTypes.forEach((currentPlanType) => {
          let states = position.presenceNodes.reduce((acc, val) => {
            if ((val['originalPlanType'] === currentPlanType) ||
               (planTypeMustMatchFilteredPlanTypes && val['planType'] === currentPlanType)) {
              if (!includes(acc, val.stateAbbr)) {
                acc.push(val.stateAbbr);
              }
            }
            return acc;
          }, []);

          states.sort();

          let salmonDocumentsData = [];
          const showSalmonDocuments = settings.table.columns.filter((el) => el.property === 'salmonDocuments');
          if (showSalmonDocuments.length) {
            salmonDocumentsData = orderData(position.salmonDocuments, 'title', showSalmonDocuments[0].titleOrder)
          }

          // If you update this, you will likely also need to update
          // src/libs/salmon-utils.js:coveragePositionTemplate
          // for data consistency.
          let row = {
            id: `${position.coveragepositionid}-${position.payer}-${currentPlanType}`,
            benefitType: position.benefitType,
            contacts: position.contacts,
            contactModalData: {
              expeditedpa: position.expeditedpa,
              peertopeer: position.peertopeer,
              contactinstructions: position.contactinstructions,
            },
            covered: position.covered,
            coveredMedicalCodes: position.coveredMedicalCodes,
            criteria: position.criteria,
            criteriaMarkdown: position.criteriamarkdown,
            documentUrl: {
              policyUrl: position.policyUrl,
              priorAuthorizationUrl: position.priorAuthorizationUrl,
            },
            indications: position.indications,
            payer: position.payer,
            planType: currentPlanType,
            priorAuthorizationText: position.priorAuthorizationText,
            priorAuthorization: position.priorAuthorizationRequired,
            states,
            product: position.product === undefined ? null : position.product.name,
            verballyConfirmed: position.verballyConfirmed,
            lastReviewDate: position.lastReviewDate,
            salmonDocuments: salmonDocumentsData,
          };

          coverageSummaryHashMap[`${position.coveragepositionid}-${position.payer}-${currentPlanType}`] = {
            states,
            indications: position.indications,
            payer: position.payer,
            planType: currentPlanType,
            criteria: position.criteria,
            policyUrl: position.policyUrl,
            stepEdit: position.stepEdit,
            stepEditText: position.stepEditText,
            coveredMedicalCodes: position.coveredMedicalCodes,
            priorAuthorizationText: position.priorAuthorizationText,
            initialdiagnosis: position.initialdiagnosis,
            initialagerequirement: position.initialagerequirement,
            prescriberRequirementsText: position.prescriberRequirementsText,
            monitoringRequirement: position.monitoringRequirement,
            coverageSummaryFields: [
              {id: 'Diagnosis', initialTherapy: position.initialdiagnosis, continuedTherapy: position.continueddiagnosis},
              {id: 'Step Requirement', initialTherapy: (position.stepEditText ? position.stepEditText : position.stepEdit.join(', ')), continuedTherapy: null},
              {id: 'Quantity Limit', initialTherapy: this._triStateDisplay(position.initialquantitylimit), continuedTherapy: this._triStateDisplay(position.continuedquantitylimit)},
              {id: 'Age requirement', initialTherapy: (position.initialagerequirement ? position.initialagerequirement : 'Unspecified'), continuedTherapy: (position.continuedagerequirement ? position.continuedagerequirement : 'Unspecified')},
              {id: 'Prescriber Requirements', initialTherapy: this._triStateDisplay(position.initialprescriberrequirements), continuedTherapy: this._triStateDisplay(position.continuedprescriberrequirements)},
              {id: '(Re)Authorization Duration', initialTherapy: (position.initialreauthorizationduration ? position.initialreauthorizationduration : 'Unspecified'), continuedTherapy: (position.continuedreauthorizationduration ? position.continuedreauthorizationduration : 'Unspecified')},
            ],
            verballyConfirmed: position.verballyConfirmed,
          };

          contactInstructionsHashMap[`${position.coveragepositionid}-${position.payer}-${currentPlanType}`] = {
            ...mapValues(keyBy(position.contacts, 'type'), 'contact'), // Add all available phone numbers as attributes.
            expeditedpa: this._triStateDisplay(position.expeditedpa, null),
            peertopeer: this._triStateDisplay(position.peertopeer, null),
            contactinstructions: position.contactinstructions,
          };

          tableRows.push(row);
        });
      });
    }

    const tableDisclaimer = lastRefreshedDate ? settings.content.tableDisclaimerExpanded.markdown.replace(/\[\[(.*)\]\]/, lastRefreshedDate) : null;
    const tableFooter = (
      <tfoot id={'disclaimer'}>
        <tr>
          <td colSpan={tableColumns.length} className={css(styleSheet.footNote)}>
            <ReactMarkdown escapeHtml={false} source={tableDisclaimer} />
          </td>
        </tr>
      </tfoot>
    );

    let filterInputs = [];
    settings.filterLayout.forEach((rowArray) => {
      let filterRowElements = [];
      rowArray.forEach((column) => {
        if (column.filter === 'state') {
          filterRowElements.push(
            <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                label={column.label}
                placeholder={column.placeholder}
                options={usStates}
                selected={selectedStates}
                isFetching={inProgress}
                handleChange={this.handleChange}
                selectHintOnEnter={false}
                type={'usStates'}/>
            </Col>
          );
        } else if (column.filter === 'payer') {
          filterRowElements.push(
            <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                label={column.label}
                placeholder={column.placeholder}
                options={payers}
                selected={selectedPayers}
                isFetching={inProgress}
                handleChange={this.handleChange}
                selectHintOnEnter={false}
                type={'payers'}/>
            </Col>
          );
        } else if (column.filter === 'plan') {
          let displayedPlanTypes = settings.displayedPlanTypes.length ? settings.displayedPlanTypes.map((planType) => {
            return {id: planType.ids.join(','), ...planType};
          }) : planTypes;

          filterRowElements.push(
            <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                label={
                  (settings.content.planTypeHelpHover.length) ?
                    <span>{column.label}
                      <ToolTipCell
                        text={settings.content.planTypeHelpHover}
                        placeholder={(<FontAwesomeIcon icon={faInfoCircle}/>)}
                        placement={'bottom'}
                        styleSheet={styleSheet}
                        id={'plantype-tooltip'}
                      />
                    </span> :
                    column.label
                }
                placeholder={column.placeholder}
                options={displayedPlanTypes}
                selected={selectedPlanTypes}
                isFetching={inProgress}
                handleChange={this.handleChange}
                hasChildren={column.hasChildren}
                selectHintOnEnter={false}
                type={'planTypes'}/>
            </Col>
          );
        } else if (column.filter === 'indication') {
          filterRowElements.push(
            <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                label={column.label}
                placeholder={column.placeholder}
                options={orderData(indications, 'id', column.orderByIds || [])}
                selected={selectedIndications}
                isFetching={inProgress}
                handleChange={this.handleChange}
                selectHintOnEnter={false}
                type={'indications'}/>
            </Col>
          );
        } else if (column.filter === 'product') {
          filterRowElements.push(
            <Col key={nodeId()} sm={column.gridWidth} className={`col-sm-offset-${column.offset}`}>
              <MultiSelect
                id={nodeId()}
                label={column.label}
                placeholder={column.placeholder}
                isFetching={inProgress}
                options={this._sortProducts(products, settings.drugId)}
                handleChange={this.handleChange}
                selected={selectedProduct}
                type={'products'}
              />
            </Col>
          );
        }
      });
      filterInputs.push(
        <Row key={nodeId()}>
          {filterRowElements}
        </Row>
      );
    });

    const searchButtonStyle = css(styleSheet.modalSuccess, styleSheet.searchButton);
    const isSearchButtonDisabled = inProgress || (Array.isArray(settings.drugId) && selectedProduct.length === 0);

    return (
      <div data-component={'Coverage'}>
        <Loader isFetching={inProgress} isFullscreen/>

        <ExitModal
          settings={settings}
          styleSheet={styleSheet}
          showExitModal={showExitModal}
          handleHide={this._handleHide}
          handleOpenDocument={this._handleOpenDocument}
        />

        <FeatureModal
          settings={settings}
          styleSheet={styleSheet}
          showFeatureModal={showFeatureModal}
          handleHide={this._handleHide}
        />

        <DisclaimerModal
          settings={settings}
          styleSheet={styleSheet}
          showDisclaimerModal={showDisclaimerModal}
          handleHide={this._handleHide}
        />

        <CoverageSummaryModal
          settings={settings}
          styleSheet={styleSheet}
          showSummaryModal={showSummaryModal}
          handleHide={this._handleHide}
          handleDocumentClick={this._handleDocumentClick}
          summaryColumns={summaryColumns}
          rowData={coverageSummaryHashMap[coverageSummaryClickedId]}
        />

        <ContactInstructionsModal
          settings={settings}
          styleSheet={styleSheet}
          showContactInstructionsModal={showContactInstructionsModal}
          handleHide={this._handleHide}
          rowData={contactInstructionsHashMap[contactInstructionsClickedId]}
        />

        <Grid>
          <Row>
            { Boolean(settings.banner.enabled) &&
            <Col xs={12} className={css(styleSheet.banner)}>
              { Boolean(settings.banner.content.html) &&
                <div dangerouslySetInnerHTML={{__html: settings.banner.content.html}} />
              }
            </Col>
            }
          </Row>
          <Row>
            {
              settings.content.searchPreface &&
              <div className={`searchPreface ${css(styleSheet.searchPreface)}`}>
                <ReactMarkdown escapeHtml={false} source={settings.content.searchPreface}/>
              </div>
            }
            {
              showInstruction &&
              <ReactMarkdown className={`instruction ${css(styleSheet.initialSearchInstruction)}`} escapeHtml={false} source={settings.content.initialSearchInstruction}/>
            }
            <ErrorAlert error={getErrorField('global', 'global', error)} alertVisible={showErrorAlert} handleDismissAlert={this._setAlert.bind(this, ERROR_ALERT, false)}/>
          </Row>
          {!settings.searchCoverageFunctionality.disabled &&
            <React.Fragment>
              {filterInputs}
              {!results && settings.content.underFiltersText &&
                <div>
                  <Row>
                    <Col>
                      <div className={css(styleSheet.underFiltersText)}>
                        <ReactMarkdown escapeHtml={false} source={settings.content.underFiltersText.markdown} />
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              <Row className={'text-center'}>
                <Button
                  type={'submit'}
                  className={ isSearchButtonDisabled ? `${searchButtonStyle} disabled` : searchButtonStyle }
                  disabled={ isSearchButtonDisabled }
                  onClick={ this._filterCoverage }
                >
                  Search
                </Button>
              </Row>
            </React.Fragment>
          }
          {
            settings.usRegionalMap &&
            <Row>
              <Col>
                <USMap
                  handleStateClick={this._handleUSMapClick}
                  selectedStates={selectedStates}
                />
              </Col>
            </Row>
          }

          {showInstruction &&
            <Row>
              <ReactMarkdown escapeHtml={false} source={settings.content.initialSupplementalInstruction.markdown} />
            </Row>
          }
          {results &&
            <Row className={css(styleSheet.results)} id='results'>
              {(!inProgress && tableRows.length === 0 && showNoRowsAlert) &&
                <Row>
                  <Alert bsStyle={'warning'} onDismiss={this._setAlert.bind(this, NO_ROW_ALERT, false)}>{settings.content.tableErrorNoResult}</Alert>
                </Row>
              }
              {(settings.stateCoverageProfiles.enabled && stateCoverageProfiles.length) &&
                <Row>
                  <StateCoverageProfileDocumentDownload
                    settings={settings}
                    styleSheet={styleSheet}
                    stateCoverageProfiles={stateCoverageProfiles}
                  />
                </Row>
              }
              {tableRows.length > 0 &&
                <div>
                  <Row>
                    <div className={css(styleSheet.footNote)}>
                      <ReactMarkdown escapeHtml={false} source={settings.content.tableDisclaimerShort.markdown} />
                    </div>
                  </Row>
                  <div className={css(styleSheet.dataTable)}>
                    <DataTable
                      rows={tableRows}
                      columns={tableColumns}
                      tableFooter={tableFooter}
                      centerPagination={true}
                    />
                  </div>
                </div>
              }
              {settings.content.underResultsText &&
                <div>
                  <Row>
                    <Col>
                      <div className={css(styleSheet.underResultsText)}>
                        <ReactMarkdown escapeHtml={false} source={settings.content.underResultsText.markdown} />
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            </Row>
          }
          <Isi settings={settings.isi} styleSheet={styleSheet}/>
          {settings.content.bottomSection &&
            <Row><div className={css(styleSheet.bottomSection)}>
              <ReactMarkdown escapeHtml={false} source={settings.content.bottomSection.markdown} />
            </div></Row>
          }
        </Grid>
      </div>
    );
  }
}

Coverage.propTypes = {
  dispatchGetStatePayerCoverageList: PropTypes.func.isRequired,
  dispatchGetStateCoverageProfiles: PropTypes.func.isRequired,
  dispatchSetStateCoverageShowAlert: PropTypes.func.isRequired,
  coveragePositions: PropTypes.array.isRequired,
  stateCoverageProfiles: PropTypes.array.isRequired,
  lastRefreshedDate: PropTypes.string.isRequired,
  usStates: PropTypes.array,
  payers: PropTypes.array,
  planTypes: PropTypes.array,
  settings: PropTypes.object.isRequired,
  styleSheet: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
  inProgress: PropTypes.bool,
  showErrorAlert: PropTypes.bool.isRequired,
  showNoRowsAlert: PropTypes.bool.isRequired,
};

/**
 * Generates the payer list from the payers found in all coverage positions.
 *
 * @param  {Object} coverage coverage object from react state.
 * @param  {Object} settings settings object from react state.
 *
 * @return {Array} payers
 */
export function restrictPayers(coverage, settings) {
  let payers = coverage.get('payers').toJS();

  if (
    typeof settings.restrictToCoveragePositionPayers !== 'undefined'
    && settings.restrictToCoveragePositionPayers === true
  ) {
    // Get all unique payers found in the coverage positions.
    let hash = {};
    for (const row of coverage.get('coveragePositions').toJS()) {
      hash[row.payer] = row.payerid;
    }

    payers = [];
    for (const payer in hash) {
      payers.push({id: hash[payer], label: payer});
    }
  }

  return payers;
}

const mapStateToProps = function (state, ownProps) {
  const {
    coverage,
  } = state;

  return {
    stateCoverageProfiles: coverage.get('stateCoverageProfiles').toJS(),
    coveragePositions: coverage.get('coveragePositions').toJS(),
    usStates: coverage.get('usStates').toJS(),
    products: coverage.get('products').toJS(),
    payers: restrictPayers(coverage, getSettings(state)),
    indications: coverage.get('indications').toJS(),
    planTypes: coverage.get('planTypes').toJS(),
    error: getError(state),
    lastRefreshedDate: getLastRefreshedDate(state),
    inProgress: getInProgress(state),
    settings: getSettings(state),
    showErrorAlert: getShowErrorAlert(state),
    showNoRowsAlert: getShowNoRowsAlert(state),
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    dispatchGetStatePayerCoverageList: (...args) => {
      dispatch(getStatePayerCoverageList(...args));
    },
    dispatchGetStateCoverageProfiles: (...args) => {
      dispatch(getStateCoverageProfiles(...args));
    },
    dispatchSetStateCoverageShowAlert: (...args) => {
      dispatch(setStateCoverageShowAlert(...args));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coverage);
