import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, MenuItem, Button} from 'react-bootstrap';
import {css} from 'aphrodite';
import './styles.scss';
import {nodeId} from 'react-widgets/libs';

/**
 * DropdownSelector
 *
 * DropdownSelector creates a dropdown selector that allows the user to select an item from a list to navigate to.
 *
 * @param {string} id - The unique identifier for the dropdown component.
 * @param {Array} menuItems - The list of items to be displayed in the dropdown menu.
 *                            example: [{"label": "Lutathera", "url": "https://lutathera.policyacumen.health"}]
 * @param {string} placeholderText - The placeholder text displayed when no item is selected. Defaults to 'Select Product'.
 * @param {string} buttonLabel - The label of the navigate button. Defaults to 'Go'.
 * @param {boolean} newTab - If true, opens the URL in a new tab. Defaults to false.
 * @param {Function} onConfirmCallBack - Optional callback function to be executed upon confirming the selection.
 * @param {Function} menuItemCallBack - Optional callback function to be executed when a menu item is selected.
 * @param {string} dropdownContainerStyle - Optional CSS classname to apply to the dropdown container.
 * @param {string} dropdownSelectorStyle - Optional CSS classname to apply to the dropdown selector.
 * @param {string} menuItemStyle - Optional CSS classname to apply to the menu items.
 * @param {string} gobuttonStyle - Optional CSS classname to apply to the confirmation button.
 * @param {string} dropdownToggleStyle - Optional CSS classname to apply to the dropdown toggle button.
 *
 **/
const DropdownSelector = ({
  id,
  menuItems,
  placeholderText = 'Select Product',
  buttonLabel = 'Go',
  newTab = false,
  onConfirmCallBack,
  menuItemCallBack,
  // Styles
  dropdownContainerStyle,
  dropdownSelectorStyle,
  menuItemStyle,
  gobuttonStyle,
  dropdownToggleStyle
}) => {

  const [selectedMenuItem, setMenuItem] = useState(placeholderText);
  const [selectedHref, setHref] = useState('');

  const _handleSelect = (eventKey, event) => {
    setMenuItem(eventKey);
    setHref(event.target.getAttribute('href'));
    event.preventDefault();
  };

  const _handleGoButton = (newTab, onConfirmCallBack) => {
    const confirmed = onConfirmCallBack ? onConfirmCallBack() : true;
    if (confirmed && selectedHref) {
      if (newTab) {
        window.open(selectedHref, '_blank');
      } else {
        window.location.href = selectedHref;
      }
    }
  };

  return (
    <div data-component={'DropdownSelector'} className={dropdownContainerStyle}>
      <Dropdown id={id} onSelect={_handleSelect} className={`dropdownselector ${dropdownSelectorStyle}`}>
        <Dropdown.Toggle className={`dropdowntoggle ${dropdownToggleStyle}`} noCaret>
          {selectedMenuItem}
          <i className="fa-solid fa-caret-down"></i>
        </Dropdown.Toggle>
        <Dropdown.Menu className={`menuitem ${menuItemStyle}`}>
          {menuItems.map(({label, url}) => (
            <MenuItem key={nodeId()} href={url} eventKey={label} onSelect={menuItemCallBack}>{label}</MenuItem>
          ))}
        </Dropdown.Menu>
        <Button
          className={`gobutton ${gobuttonStyle}`}
          onClick={() => _handleGoButton(newTab, onConfirmCallBack)}
        >{buttonLabel}</Button>
      </Dropdown>
    </div>
  );
};

DropdownSelector.propTypes = {
  id: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  placeholderText: PropTypes.string,
  buttonLabel: PropTypes.string,
  newTab: PropTypes.bool,
  onConfirmCallBack: PropTypes.func,
  menuItemCallBack: PropTypes.func,
  dropdownStyle: PropTypes.string,
  dropdownSelectorStyle: PropTypes.string,
  menuItemStyle: PropTypes.string,
  gobuttonStyle: PropTypes.string,
  dropdownToggleStyle: PropTypes.string,
}


export default DropdownSelector;